
 .boxcol{
    background: "linear-gradient(178.36deg, rgb(0, 162, 183) 1.4%, rgba(0, 0, 0, 0.9) 300.49%)";
} 


  
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    opacity: .10;
  }
  
  .modal-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
  }

  .wrapper{
    -webkit-filter: blur(8px);
    -ms-filter: blur(8px);
    -moz-filter: blur(8px);
    -o-filter: blur(8px);
    filter: blur(8px);
} 
  
  .modal {
    z-index: 100;
    background: white;
    position: relative;
    margin: 1.75rem auto;
    border-radius: 3px;
    max-width: 500px;
    padding: 2rem;
    
  }
  
  .modal-header {
    display: flex;
    justify-content: flex-end;
  }
  
  .modal-close-button {
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    opacity: .3;
    cursor: pointer;
    border: none;
  }

  table {
    counter-reset: tableCount;     
  }
  .counterCell:before {              
    content: counter(tableCount); 
    counter-increment: tableCount; 
  }
  
  select{
    width: 150px;
    height: 30px;
    padding: 5px;
    color: gray;
  }
  select option { color: gray; }
  select option:first-child{
    color: gray;
  }

  input[type=number]::-webkit-inner-spin-button {
    opacity: 1
}

/* .highlighted-date {
  background-color: #ffc107;
  color: #000;
  border-color: blue;
  border: "19px";
} */