.bg-transparent {
  background-image: url('assets/images/black-screen.png');
  opacity: .6 !important;
}

.table-custom {
  font-family: arial, sans-serif;
  border-collapse: collapse;
}

.table-custom td {
  border: 1px solid #dddddd;
  padding: 8px;
}

.table-custom thead {
  border: 1px solid #000 !important;
  padding: 8px;
  background-color: #dddddd;
}
